<template>
  <div>
    <helloWorld />
    <!-- <div class="type-banner">
      <img :src="albumInfo.coverPic" alt="">
    </div> -->
    <div class="author-avatar" style="margin-top:100px">
      <img :src="albumInfo.coverPic" alt="">
    </div>
    <p class="author-name">{{albumInfo.title}}</p>
    <p class="author-desc">{{albumInfo.desc}}</p>
    <div class="owner">
      <div class="owner-item" style="border-bottom-left-radius: 10px;border-top-left-radius: 10px;overflow: hidden;">
        <p class="owner-num">{{total}}</p>
        <p class="owner-desc">项目</p>
      </div>
      <div class="owner-item">
        <p class="owner-num">{{albumInfo.createdBy}}</p>
        <p class="owner-desc">拥有者</p>
      </div>
      <div class="owner-item">
        <p class="owner-num">{{albumInfo.floorPrice}}</p>
        <p class="owner-desc">底价</p>
      </div>
      <div class="owner-item" style="border-bottom-right-radius: 10px;border-top-right-radius: 10px;overflow: hidden;">
        <p class="owner-num">{{albumInfo.volumeTraded}}</p>
        <p class="owner-desc">交易量</p>
      </div>
    </div>
    <div v-if="total == 0">
      <el-empty description="该专辑下暂无藏品"></el-empty>
    </div>
    <div v-else class="works">
      <div class="works-item" v-for="(item, index) in collectList" :key="index" @click="toDetail(item)">
        <div class="img-box">
          <img class="collcet-pic" :src="item.pic" alt="">
        </div>
        <div style="padding:0 14px">
          <p class="works-name">{{item.colName}}</p>
          <p class="creator" @click.stop="toUser(item)">{{item.creator.nickname}}</p>
          <div class="price">
            <p>价格：<span>￥{{item.price}}</span></p>
            <!-- <p>成交价：<span>￥{{item.transaction}}</span></p> -->
          </div>
          <div class="likenum">
            <img src="@/assets/xihuan.svg" alt="">
            <a>{{item.likes}}</a>
          </div>
        </div>
      </div>
    </div>
    <pagination
          v-show="total>0"
          :total="total"
          :page-sizes="[12, 24, 36, 48, 50]"
          :page.sync="queryParams.PageNum"
          :limit.sync="queryParams.PageSize"
          @pagination="getCollect"
        />
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import {getAlbumDetail, getCollectList, toLike, downImg} from '../../api/index'
import { mapGetters } from 'vuex'
export default {
  name: 'List',
  components: {
    helloWorld
  },
  data() {
    return {
      env: process.env.VUE_APP_BASE_API,
      obj: {},
      albumInfo: {},
      albumId: '',
      title: '',
      collectList: [],
      total: 0,
      queryParams: {
        PageNum: 1,
        PageSize: 12,
      },
    }
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
    this.title = this.$route.query.title
    this.albumId = this.$route.query.albumId
    this.getInfo()
    this.getCollect()
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  methods: {
    toUser(item) {
      this.$router.push('../user?userCode=' + item.creator.userCode + '&&id='+item.creator.id)
    },
    // 点赞
    getLike(item) {
      let obj = {
        colCode: item.colCode
      }
      toLike(obj).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功')
          this.getCollect()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 藏品列表
    getCollect() {
      this.queryParams.albumId = this.albumId
      getCollectList(this.queryParams).then(res => {
        if(res.code ==0) {
          this.collectList = res.data.list
          this.total = res.data.total
          for (var s = 0; s < this.collectList.length; s++) {
            if (this.collectList[s].saleMode != 0 ){
              this.collectList[s].pic = this.env+this.collectList[s].pic
            }else {
              this.getCollcetPic(s, this.collectList[s].pic)
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getCollcetPic(i, pic) {
      let a = {
        path: pic
      }
      downImg(a).then(res => {
        let blob = new Blob([res]);   // 返回的文件流数据
        let url = window.URL.createObjectURL(blob);  // 将他转化为路径
        this.collectList[i].pic = url;
      })
    },
    // 专辑详情
    getInfo() {
      let obj= {
        title: this.title
      }
      getAlbumDetail(obj).then(res => {
        if (res.code ==0) {
          this.albumInfo = res.data
          this.albumInfo.coverPic = this.env + this.albumInfo.coverPic
        } else {
          this.$message.error(res.message)
        }
      })
    },
    toDetail(row) {
      this.$router.push('./detail?colCode=' + row.colCode)
    }
  }
}
</script>
<style scoped>
.type-banner img {
  width: 100%;
  height: 200px;
}
.author-avatar {
  text-align: center;
}
.author-avatar img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-top: -50px;
}
.author-name {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}
.author-desc {
  font-size: 12px;
  color: #666;
  text-align: center;
  width: 68%;
  margin: auto;
  margin-top: 10px;
  line-height: 20px;
}
.owner {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.owner-item {
  padding: 10px 30px;
  border: 1px solid #eee;
}
.owner-num {
  /* font-weight: 600; */
    font-size: 14px;
    color: rgb(4, 17, 29);
    text-align: center;
}
.owner-desc {
  margin-top: 2px;
    font-size: 12px;
    text-align: center;
    color: rgb(138, 147, 155);
}
.owner-item:hover{
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}
.works {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1180px;
  margin: auto;
  margin-top: 20px;
}
.works-item {
  margin: 10px 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.img-box {
  width: 100%;
  max-height: 250px;
  text-align: center;
}
.collcet-pic {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 230px;
}
.works-item img {
  width: 100%;
  object-fit: cover;
}
.works-item:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  /* margin-top: 6px; */
}
.works-name {
  font-size: 18px;
  margin-top:4px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1; /*超出几行后省略号*/
overflow: hidden;
}
.creator {
  font-size: 12px;
  color: #666;
  margin-top:8px;
  margin-bottom: 8px;
}
.price {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.price p {
  color: #999;
}
.price p span {
  color: #333;
}
.likenum {
  padding: 8px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.likenum a {
  font-size: 14px;
  color: #999;
  /* margin-left: 4px; */
}
.likenum img {
  width: 18px;
  margin-right: 4px;
}
@media screen and (max-width: 720px){
  .works{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 720px) and (max-width: 900px){
  .works{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>